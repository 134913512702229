export default {
  urban_title: "城市规划与设计",
  urban_subtitle: "为全球客户提供创新而卓越的一站式解决方案",
  urban_content: `作为新加坡地产业的领跑者，中冶新加坡公司凭借自身丰富的城市规划、开发建设与运营管理经验，不断丰富项目类型和规模，将发展市场由新加坡拓展到东南亚其他国家和澳大利亚、英国等国际市场。\n
  公司在地产项目的基础上，充分借鉴中新两国在城市发展、城市治理、产业导入、社区营造，以及智能化建设和智慧管理等方面的广泛经验，提出创新的城市社区解决方案，通过精心设计的城市生活模块，为不同尺度的发展项目提供更灵活、立体的业态和居住模式组合。
  `,
  urban_service_title: "综合性服务:",
  urban_service_content: "我们通过全面的视角为每个项目提供创新性的解决方案。无论单项设计，还是覆盖项目全周期的整体解决方案，MCC的团队都将提供专业服务。",
  urban_services: {
    study: "可行性研究",
    planning: "城市规划",
    design: "城市设计",
    architectural: "建筑设计",
    sustainable: "可持续环境规划",
    transportation: "运输/交通/道路规划",
    resource: "水资源战略与管理",
    wastewater: "废水管理规划",
    stormwater: "雨洪管理规划",
    sidewide: "市政设施规划设计",
    solid: "固体废弃物管理策略",
    power: "电力规划",
  },
  urban_project_find_out_more: "查看更多"
}