import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/home/index.vue'),
  },
  {
    path: '/about-us',
    component: () => import('../views/home/About.vue'),
  },
  {
    path: '/history',
    component: () => import('../views/home/History.vue'),
  },
  {
    path: '/all-news',
    component: () => import('../views/news/AllNews.vue'),
  },
  {
    path: '/news',
    component: () => import('../views/news/News.vue'),
  },
  {
    path: '/all-photos',
    component: () => import('../views/news/AllPhotos.vue'),
  },
  {
    path: '/panoramas-test',
    component: () => import('../views/tests/PanoramasTest.vue'),
  },
  {
    path: '/panoramas-31clunyhill',
    component: () => import('../views/tests/clunyHill.vue'),
  },

  {
    path: '/panoramas/landmark',
    component: () => import('../views/tests/LandmarkView.vue'),
  },
  {
    path: '/panoramas/BatamPpvcFactory',
    component: () => import('../views/tests/BatamPpvcFactory.vue'),
  },
  {
    path: '/employee-operation',
    component: () => import('../views/home/EmployeeOperation')
  },
  {
    path: "/loading",
    name: "Loading",
    component: () => import("../views/shared/Loading.vue"),
  },
  {
    path: '/project-detail',
    component: () => import("@/views/project-detail/index.vue"),
  },
  {
    path: '/salary-caculator',
    component: () => import("../views/salary-caculator/SalaryCaculator.vue"),
  },
  {
    path: '/construction',
    component: () => import('../views/construction/index.vue')
  },
  {
    path: "/property-dev",
    component: () => import("@/views/property-dev/index.vue"),
  },
  {
    path: "/urban-design",
    component: () => import("@/views/urban-planning/index")
  },
  {
    path: "/hr",
    component: () => import("@/views/Hr/index"),
    meta: {
      fullpage: true
    }

  },
  {
    path: "/hr/awards",
    component: () => import("@/views/Hr/awards"),
    meta: {
      fullpage: true
    }

  },
  {
    path: "/hr/talent",
    component: () => import("@/views/Hr/talentAll"),
    meta: {
      fullpage: true
    }

  },
  {
    path: "/hr/evp",
    component: () => import("@/views/Hr/evp"),
    meta: {
      fullpage: true
    }

  },
  {
    path: "/hr/policy",
    component: () => import("@/views/Hr/policy"),
    meta: {
      fullpage: true
    }

  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router
