export default {
  pd_overview: 'Overview',
  pd_location: 'Location',
  pd_detail: 'Detail',
  pd_project_name: 'Project Name',
  pd_project_type: 'Project Type',
  pd_developer: 'Developer',
  pd_tenure: 'Tenure',
  pd_PSF: 'Square',
  pd_completion_year: 'Completion Year',
  pd_total_units: 'Total Units',
  pd_description: 'Description',
  pd_facilities: 'Facilities and amenities',
  pd_website: 'Project Website',
  pd_project_location: 'Location',
}