import Vue from 'vue';
const $ = require('jquery');

function _setBackgroundImage(elsMap) {
  // 获取窗口的高度
  const wh = $(window).height();
  // 获取滚动的高度
  const winScroll = $(window).scrollTop();

  // 每次滚动，为没有设置背景图的元素设置背景
  for (let i = 0; i < elsMap.length; i++) {
    if (elsMap[i].h <= winScroll + (wh - 200)) {
      let el = elsMap[i].el;
      el.style.backgroundImage = "url(" + el.dataset.src + ")";
    }
  }
}

function _getAllElements(els) {
  return $(els).map(function () {
    return { el: this, h: $(this).offset().top };
  });
}

function _setBGWhenScroll(el) {
  // 找到所有需要懒加载的元素
  const els = el.querySelectorAll('.lazy');
  const elsMap = _getAllElements(els);

  let f = function () {
    // 如果滚动到了页面底部，需要清除监听事件
    if (window.scrollY + 200 > el.clientHeight) {
      window.removeEventListener('scroll', f)
    }
    _setBackgroundImage(elsMap);
  };

  window.addEventListener('scroll', f);
}

Vue.directive('iScroll', {
  inserted: function (el) {
    _setBGWhenScroll(el);
  },
  componentUpdated: function (el) {
    _setBGWhenScroll(el);
  }
});