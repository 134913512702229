import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import components from './components/index';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueCarousel from 'vue-carousel';
import messages from './i18n/index';
import VueI18n from 'vue-i18n';
import VueSocialSharing from 'vue-social-sharing'
window.$ = window.jQuery = require('jquery');
import '@/directives/index.js';
import http from "./api/http";
import url from './api/url';
import NProgress from "nprogress";
import page from 'v-page';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 全局引入富文本
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.config.productionTip = false;
Vue.use(components);
Vue.use(VueSocialSharing);
Vue.use(ElementUI)
Vue.use(VueQuillEditor);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDqaMato3Fx8Svy8pK66oBpJYHnOLkMGgE',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
});
Vue.use(VueCarousel);
Vue.use(VueI18n);
Vue.use(page, { language: 'en', pageSizeMenu: [50] });

Vue.prototype.$progress = NProgress;
Vue.prototype.$http = http;
Vue.prototype.$url = url;


const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
