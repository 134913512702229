import construction from './construction.js'
import project_detail from './project-detail'
import property_dev from './property-dev'
import urban_design from './urban-design'
import home from './home.js'
import others from './others.js'
import news from './news'
import history from './history'

export default {
  ...construction,
  ...project_detail,
  ...property_dev,
  ...urban_design,
  ...home,
  ...others,
  ...news,
  ...history,
}