/* import zh from './zh';
import en from './en'; */
import en from './en/index'
import zh from './zh/index'

export default { 
  zh: {
    message: zh
  },
  en: {
    message: en,
  }
 }