var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"flex-row position-fix bg-c-0 z-index-100"},[_c('p',{staticClass:"flex-1 flex-row align-items-center left"},[_c('router-link',{staticStyle:{"outline":"none"},attrs:{"to":"/"}},[_c('img',{staticClass:"cursor-pointer",staticStyle:{"width":"3.244vw","height":"2.063vw"},attrs:{"src":_vm.imgSvg}})])],1),_c('div',{staticClass:"home-btn",on:{"click":function($event){return _vm.goto('')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.home"))+" ")]),_c('div',{staticClass:"home-btn-line"}),_c('div',{staticClass:"lang-switch"},[_c('p',{staticClass:"p-1",style:({
        color:
          _vm.lang === false ? 'rgba(74, 145, 242, 1)' : 'rgba(149, 149, 149, 1)',
      })},[_vm._v(" 中文 ")]),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lang),expression:"lang"}],attrs:{"type":"checkbox","checked":""},domProps:{"checked":Array.isArray(_vm.lang)?_vm._i(_vm.lang,null)>-1:(_vm.lang)},on:{"change":[function($event){var $$a=_vm.lang,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.lang=$$a.concat([$$v]))}else{$$i>-1&&(_vm.lang=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.lang=$$c}},function($event){return _vm.changeLangSimple()}]}}),_c('span',{staticClass:"slider round"})]),_c('p',{staticClass:"p-2",style:({
        color:
          _vm.lang === true ? 'rgba(74, 145, 242, 1)' : 'rgba(149, 149, 149, 1)',
      })},[_vm._v(" EN ")])]),(!_vm.showMenuList)?_c('p',{ref:"boxMenu",staticClass:"box-menu cursor-pointer flex-row",on:{"click":_vm.onClickMenu}},[_c('span',{staticClass:"text-menu"},[_vm._v(_vm._s(_vm.$t("message.menu")))]),_vm._m(0)]):_vm._e(),_c('div',{ref:"sideMenu",staticClass:"menu-list",style:({ width: _vm.showMenuList ? '413px' : 0 }),attrs:{"tabindex":"0"}},[(_vm.showMenuList)?_c('div',{staticClass:"row-icon flex-row",on:{"blur":function($event){_vm.showMenuList = false}}},[_c('i',{staticClass:"iconfont iconclose",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showMenuList = false}}})]):_vm._e(),_c('div',{staticClass:"links-1 flex-column"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.home"))+" ")]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('urban-design')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.consultation"))+" ")]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('property-dev')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.real-estate"))+" ")]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('construction')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.construction"))+" ")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"links-2 flex-column"},[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('all-news')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.mcc-news"))+" ")]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('about-us')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.about-us"))+" ")]),_c('div',{staticClass:"item",on:{"click":function($event){return _vm.goto('history')}}},[_vm._v(" "+_vm._s(_vm.$t("message.header.history"))+" ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon-menu position-relative"},[_c('em',{staticClass:"line line-1"}),_c('em',{staticClass:"line line-2"}),_c('em',{staticClass:"line line-3"})])
}]

export { render, staticRenderFns }