export default {
  "history": [
    {
      "year": 1996,

      "content": "MCC Group settles in Singapore, officially entering the Singapore and Southeast Asia market",
      "content-detail": "MCC Group settles in Singapore, officially entering the Singapore and Southeast Asia market"
    },
    {
      "year": 1997,

      "content": "China Jingye Construction Engineering (Singapore) Pte Ltd was established",
      "content-detail": "Steel structure engineering was recognized by the market as the company's core competency."
    },
    {
      "year": 2006,

      "content": "China Jingye Engineering Corporation Limited (Singapore Branch) was founded and has participated in the construction of 3200 government HDB units",
      "content-detail": "China Jingye Engineering Corporation Limited (Singapore Branch) was founded and has participated in the construction of 3200 government HDB units"
    },
    {
      "year": 2007,

      "content": "The company was the appointed structural steel contractor for Resorts World Sentosa, becoming a renowned brand in the structural steel industry",
      "content-detail": "The company was the appointed structural steel contractor for Resorts World Sentosa, becoming a renowned brand in the structural steel industry"
    },
    {
      "year": 2008,

      "content": "The company became the general contractor for the construction of Universal Studios Singapore",
      "content-detail": "The company constructed the world’s A-class theme park in 18 months, creating a new history for the theme park construction industry. This laid a solid foundation for the development of theme park construction, which is an important part of MCC’s comprehensive business system"
    },
    {
      "year": 2010,

      "content-1": "MCC Land (Singapore) Pte Ltd was established",
      "content-1-detail": "The company undertakes real estate development in Singapore and the surrounding regions for China Metallurgical Group Corporation. As the representative for the Group's joint-stock company, it is also responsible for the business activities in Singapore",
      "content-2": "CW02 Civil Engineering qualification upgraded to A1",
      "content-2-detail": "Allowing for unrestricted bidding on Singapore government projects",

    },
    {
      "year": 2017,

      "content": "Transformation to an integrated urban operator, exploring regional markets",

      "content-detail": "Established the vision of “becoming a leading global city operator with high efficiency and innovation”, The company developed its regional markets and has expanded its business to Malaysia, Indonesia, Cambodia, Australia, and other countries."
    },
    {
      "year": 2020,

      "content": "The company became the general contractor for the design and construction of Singapore’s Mandai Rainforest Park",
      "content-detail": "The company was awarded the design and construction contract for the Mandai Rainforest Park project, challenging the construction of ecotourism projects under the pandemic. \n\n The company won the bid for the Short Street Hotel project, diving into the field of hotel construction",

    }
  ],
}