import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  /* plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    })
  ], */
  state: {
    showContectUs: false,
    lang: 'en',
    // 测试地址
    // mccPortalUrl:'http://192.168.102.218:9188/#/homepage'
        // 正式地址
    mccPortalUrl:'https://sso.mcc.sg/#/login?redirect=%2Fhomepage'
  },
  mutations: {
    flip(state) {
      state.showContectUs = !state.showContectUs
    },
    changeLang(state,what ) {
      state.lang = what
    }
  },
  actions: {
  },
  modules: {
  }
})
