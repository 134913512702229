import axios from 'axios'
import NProgress from 'nprogress'

axios.defaults.timeout = 5000;

axios.interceptors.request.use(config => {
    NProgress.start();
    return config
}, error => {
    // Message.error({ message: 'Request timeout!' });
    window.alert('Request timeout!')
    return Promise.reject(error)
});

axios.interceptors.response.use(response => {
    return response
}, err => {
    if (err && err.response) {
        switch (err.response.status) {

            case 400: console.log('Request Error(400)'); break;
            case 401: console.log('请求路径错误'); break;
            case 403: console.log('拒绝访问(403)'); break;
            case 404: console.log('Request error (404)'); break;
            case 408: console.log('Request timeout(408)'); break;
            case 500: console.log('erver error (500)'); break;
            case 501: console.log('服务未实现(501)'); break;
            case 502: console.log('Network error (502)'); break;
            case 503: console.log('服务不可用(503)'); break;
            case 504: console.log('Request timeout (504)'); break;
            case 505: console.log('HTTP版本不受支持(505)'); break;
            default: console.log('连接出错(${err.response.status})!');
        }
    } else {
      console.log('Failed to connect to server, please check your internet!');
    }

    Promise.resolve(err.response);
});

function checkStatus(response) {
    NProgress.done();

    if (response.status === 200 || response.status === 304) {
        return response.data;
    } else {

        window.alert('Network error, please try again later!')
    }
}



export default {
    post(url, data, config = {}) {
        return axios.post(url, data, config).then(checkStatus);
    },
    get(url, data) {
        return axios.get(url, data).then(checkStatus);
    },
    put(url, data, config = {}) {
        return axios.put(url, data, config).then(checkStatus);
    },
    delete(url, data) {
        return axios.delete(url, { data }).then(checkStatus);
    },
}