export default {
  'working-days': 'Working days (Mon to Fri)',
  'working-days-at-weekend': 'Working days (Sat)',
  'unit-price': 'Hourly unit price',
  'total-hours': 'Total hours',
  'dining-fee': 'Dining fee',
  'foreman-fee': "Foreman's fee",
  'label-caculate': 'Caculate',
  'label-total-salary': 'Total salary in {month} {year}:',
  "header": {
    "home": "Home",
    "consultation": "Urban Planning & Design",
    "real-estate": "Property Development",
    "construction": "Construction",
    "about-us": "About Us",
    "history": "History",
    "mcc-news": "News",
    "language": "Language: ",
  },
  "footer": {
    "development": "DEVELOPMENT",
    "construction": "CONSTRUCTION",
    "staff": "STAFF PAGE",
    "employee": "Employee Operations",
    "other": "OTHER",
    "career": "Career",
    "privacy": "Privacy",
    "language": "Language",
    "contact": "Contact Us",
  },
}