export default {
  pd_overview: '项目概览',
  pd_location: '项目位置',
  pd_detail: '项目详情',
  pd_project_name: '项目名称',
  pd_project_type: '项目类型',
  pd_developer: '开发商',
  pd_tenure: 'Tenure',
  pd_PSF: '项目面积',
  pd_completion_year: '完成时间',
  pd_total_units: 'Total Units',
  pd_description: '项目描述',
  pd_facilities: 'Facilities and amenities',
  pd_website: '项目网址',
  pd_project_location: '项目位置',
}