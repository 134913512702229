export default {
  'working-days': '上班天数（周一～周五）',
  'working-days-at-weekend': '上班天数（周六）',
  'unit-price': '小时单价',
  'total-hours': '小时总数',
  'dining-fee': '餐食费',
  'foreman-fee': '领班费',
  'label-caculate': '计算',
  'label-total-salary': '{year}年{month}月工资总计：',
  "header": {
    "home": "首页",
    "consultation": "城市规划与设计",
    "real-estate": "地产开发",
    "construction": "建筑工程",
    "about-us": "关于我们",
    "history": "公司发展史",
    "mcc-news": "公司新闻",
    "language": "语言: ",
  },
  "footer": {
    "development": "开发",
    "construction": "工程",
    "staff": "员工页面",
    "employee": "员工中心",
    "other": "其它",
    "career": "工作机会",
    "privacy": "隐私",
    "language": "语言",
    "contact": "联系我们",
  },
}