import construction from './construction.js'
import project_detail from './project-detail'
import property_dev from './property-dev'
import urban_design from './urban-design'
import home from './home'
import others from './others'
import history from './history'
import news from './news'

export default {
  ...construction,
  ...project_detail,
  ...property_dev,
  ...urban_design,
  ...home,
  ...others,
  ...history,
  ...news,
}