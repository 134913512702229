export default {
  "history": [
    {
      "year": 1996,
      "content": "中冶集团落户新加坡，正式进入新加坡及东南亚市场",
      "content-detail": "中冶集团落户新加坡，正式进入新加坡及东南亚市场"
    },
    {
      "year": 1997,

      "content": "中国京冶建设工程（新加坡）有限公司注册成立",
      "content-detail": "钢结构工程作为公司优势技术得到市场认可"
    },
    {
      "year": 2006,

      "content": "中国京冶工程技术有限公司（新加坡分公司）注册成立, 先后参建3200个政府组屋单位",
      "content-detail": "2006年成立中国京冶工程技术有限公司（新加坡分公司），先后参建3200个政府组屋单位"
    },
    {
      "year": 2007,

      "content": "The company was the appointed structural steel contractor for Resorts World Sentosa, becoming a renowned brand in the structural steel industry"
    },
    {
      "year": 2008,

      "content": "成为新加坡环球影城的施工总承包商",
      "content-detail": "仅用18个月的时间，打造世界A级主题公园建设史上的奇迹。为中冶集团“四梁八柱”综合业务体系的重要部分——特色主题工程的发展奠定了坚实的基础。"
    },
    {
      "year": 2010,

      "content-1": "中冶置业（新加坡）有限公司注册成立",
      "content-1-detail": "负责中冶集团在新加坡及周边地区的房地产开发业务，同时作为股份公司的海外区域性公司，在新加坡开展经营活动",
      "content-2": "基础工程CW02资质升级至A1，可无限制投标新加坡政府工程",
      "content-2-detail": "可无限制投标新加坡政府基础工程"
    },
    {
      "year": 2017,

      "content": "向城市综合运营商转型，开拓区域市场",
      "content-detail": "确立“成为高效创新的全球领先城市运营商”之愿景。 开拓区域市场，先后在马来西亚、印尼、柬埔寨、澳大利亚等国家开展业务"
    },
    {
      "year": 2020,

      "content": "成为新加坡万礼热带雨林公园的设计施工总承包商",
      "content-detail": "疫情下挑战生态旅游项目建设，中标万礼热带雨林公园项目设计施工总承包。 开辟酒店建设新领域，中标从肃街酒店总承包项目",
    }
  ],
}