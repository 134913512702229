export default {
  con_company_name: 'MCC Singapore',
  con_title: 'Construction',
  con_content: `
  China Jingye Engineering Corporation Limited (Singapore Branch) is a
  registered contractor with the Building and Construction Authority of
  Singapore (BCA) and the only State-owned enterprise in Singapore to obtain
  the highest A1 grade in both General Building (CW01) and Civil Engineering
  (CW02) categories. The company is influential in Singapore with excellent
  performance in both public and private construction sectors. The company
  is the main contractor for Singapore’s iconic landmark projects –
  Universal Studios Singapore and Maritime Experiential Museum, Resorts
  World, Sentosa. <br/><br/>
  
  China Jingye Construction Engineering (Singapore) Private
  Limited has extensive experience in the private civil engineering sector.
  As the general main contractor for MCC Land, it is responsible for most of
  its property development projects. The company has been part of MCC
  Singapore since its inception in 1996. It has accumulated comprehensive
  engineering performance and experiences in turnkey, specialised
  contracting, supply and subcontracting works.
  `,
  con_quality: 'Qualified Construction',
}