export default {
  "welcome": "您好,\n 欢迎来到中冶新加坡!",
  "about-title": "关于我们",
  "intro-title": "我们的业务",
  "intro-subtitle-1": "城市规划与设计",
  "intro-subtitle-2": "地产开发",
  "intro-subtitle-3": "建筑工程",
  "about-poetry": "“城市让生活更加美好，我们将不断为城市发展助力，\n\n 在积极融入世界的同时，\n\n也以涓滴之力改变世界面貌，\n\n 创造无限生活。”",
  "about": "响应国家“走出去”号召，我们在1992年进入新加坡市场。二十多年来，中冶新加坡经历重重考验，从小到大、由弱而强，一步步迈上新的台阶。公司业务历经劳务分包、专业分包、工程总承包、房地产发展商等几次转型升级，目前正在推进从“房地产开发商”到“城市综合运营商”的转型升级，实现公司业务的多元化与数字化，向高效创新、全球领先的城市综合运营商这一公司愿景而奋斗。",
  "about-full": "中国中冶新加坡（MCC SINGAPORE）是中国冶金科工集团有限公司（简称中冶集团）旗下全资子公司中冶建筑研究总院有限公司的海外分公司。中国中冶新加坡（MCC SINGAPORE）作为中冶集团的海外区域性公司，代表中冶集团在新加坡开展区域性经营活动，主要从事城市规划、项目策划、城市及地产开发、项目管理、建筑工程及基础工程总承包、钢结构专业工程承包等业务。公司先后在钢结构专业工程、大型综合娱乐项目、房地产开发以及项目管理、公共基础设施项目等领域取得重大突破和显著成效，连续三年被评为“中国企业走进东盟十大成功企业奖”。\n\n1992年扬帆南洋，经过二十多年的发展，中冶蓝已遍布新加坡全岛的各个角落，并逐步向其他国家和地区延伸。在海外的发展过程中，中冶新加坡始终坚持绿色、可持续、智慧型的发展理念，以城市规划及项目策划为先导，以国际一流的工程承包为基础，推动公司业务持续、稳定、健康发展，力争成为区域有影响力的创新型城市发展与运营商。\n\n自2006年起，中国中冶新加坡公司的三间公司均在新加坡陆续通过ISO9001、ISO14001和ISO18001、bizSAFE Star和bizSAFE Partner认证，并陆续获得新加坡建设局颁发的绿色建筑白金奖和超金奖、绿色文明施工企业杰出奖、建筑业生产力实践与创新金奖、新加坡建屋发展局颁发的2012年安全大奖、2012年优质工程奖、2013年优质工程奖、新加坡人力部颁发的企业安全奖、新加坡钢结构协会颁发的钢结构设计大奖等殊荣。\n\n城市让生活更加美好，我们将不断为城市发展助力，在积极融入世界的同时，也以涓滴之力改变世界面貌，创造无限生活。",
  "read-more": "了解更多",
  'about-button': '了解更多',
  'menu': '菜单',
  "history-title": "中冶新加坡发展史",
  "mcc-history": "中冶发展史",
  "culture": {
    /* 'title': '我们的文化',
    "culture": "企业文化", */
    "mission": "我们的使命",
    "vision": "我们的愿景",
    'content-1': "创造无限生活",
    'content-2': "高效创新 全球领先的城市运营商",
    // 'content-3': "与众不同, 勇于创新, 合作共赢, 诚信如一, 目标导向",
  },

  "urban-design": {
    "shortDescription": "作为新加坡地产业的领跑者，中冶新加坡公司凭借自身丰富的城市规划、开发建设与运营管理经验，不断丰富项目类型和规模，并将发展市场由新加坡拓展到东南亚其他国家和澳大利亚、英国等国际市场。",
  },

  "property-dev": {
    "shortDescription": "中冶置业（新加坡）有限公司已在新加坡独资、合资及管理开发了15个商住综合、私人公寓、执行共管公寓等房地产项目，包括8000余个住宅单位，可售总面积达60多万平方米，在有限的空间里为千万业主“创造无限生活”。",
  },

  "construction": {
    "shortDescription": "中冶新加坡在房屋建筑、基础设施工程双领域均达到新加坡建设局A1最高级别，并拥有排水、机电双L6资质，多个完工项目获得新加坡建设局及其他有关机构多项安全、设计类奖项肯定，工程技术实力得到新加坡业界广泛认同。",
  }
}