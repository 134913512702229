let addr = '/memo';
// let hrSrc='/hrApi'
let host_image = 'https://mcc-picture-factory.obs.ap-southeast-3.myhuaweicloud.com';
if (process.env.NODE_ENV === 'production') {
  host_image = 'https://mcc-picture-factory.obs-website.ap-southeast-3.myhuaweicloud.com';
}

export default {
  news: {
    host_image: host_image,
    url_news_list: `${addr}/news/newsList/`,
    url_news_latest: `${addr}/news/latestNews/`,
    url_news_like: `${addr}/news/newsLike/`,
    url_news_detail: `${addr}/news/newsDetail/`,
    url_news_changeLang: `${addr}/news/newsChangeLanguage/`,
  },

  memo: {
    host_image: host_image,
    url_memo_detail: `${addr}/aiis-memo/memoInfo/getDetail/`,
    url_memo_recommend: `${addr}/aiis-memo/memoInfo/getRecommend/`,
    url_memo_all: `${addr}/aiis-memo/memoInfo/getAll/`,
    url_memo_public: `${addr}/aiis-memo/memoInfo/getExternalPublic`,
    url_memo_public_info: `${addr}/aiis-memo/memoInfo/getPublicInfo`,
  },
  hr: {
    evpList:`/hrApi/mcc-base/evp/page`,
    talentList:`/hrApi/mcc-base/talentAmbassador/page`,
    awartList:`/hrApi/mcc-base/awardsRecognition/page`,

  },

  browse: {
    url_memo_browseSave: `${addr}/aiis-memo/browseHistory/save`,
  }
}

