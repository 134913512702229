export default {
  urban_title: "Urban Planning & Design",
  urban_subtitle: "Provides one-stop solution to create places that enrich people’s lives and help projects succeed",
  urban_content: `With more than 10 years of real estate development experience in Singapore, MCC Land has built-up strong capacities in urban planning, real estate development, construction and operation management. MCC Land has been venturing into different type and scale of development as well as expanding from Singapore to other Southeast Asian countries, Australia and the United Kingdom.\n
  With the extensive knowledge and experience of China and Singapore in urban planning, urban governance, and industrial development, etc., MCC Land is committed to creating a sustainable, resilient, smart and high-quality of life.
  `,
  urban_service_title: "Integrated Services:",
  urban_service_content: "We bring innovative solutions by approaching projects from many different angles. MCC professionals provide integrated or individual services including:",
  urban_services: {
    study: "Feasibility Study",
    planning: "Urban Planning",
    design: "Urban Design",
    architectural: "Architectural & Building Design",
    sustainable: "Sustainable Environmental Planning",
    transportation: "Transportation / Traffic/Roads Planning",
    resource: "Integrated Water Resource Strategy & Management",
    wastewater: "Wastewater Management Planning",
    stormwater: "Stormwater Management Planning",
    sidewide: "Sitewide Utilities Strategy and Planning Design",
    solid: "Solid waste Strategy and Management",
    power: "Power Supply Planning Design",
  },
  urban_project_find_out_more: "Find out more",
}