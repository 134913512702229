<template>
  <footer>
    <div class="flex-row justify-content-center">
      <ul class="flex-row">
        <li>
          <img :src="imgSvg" style="width: 5.5rem; height: 4.6rem" />
        </li>

        <li>
          <p class="f-f-arial f-c-15 f-s-8 font-bold">
            {{ $t("message.footer.development") }}
          </p>
          <gap :height="18" />
          <p class="f-f-arial f-s-11 f-c-16">T: +65 6508 2288</p>
          <gap :height="10" />
          <p class="f-f-arial f-s-11 f-c-16">E:info@mcc.sg</p>

          <gap :height="38" />

          <p class="f-f-arial f-s-8 f-c-15 font-bold">
            {{ $t("message.footer.construction") }}
          </p>
          <gap :height="18" />
          <p class="f-f-arial f-s-11 f-c-16">T: +65 6766 6883</p>
          <gap :height="10" />
          <p class="f-f-arial f-s-11 f-c-16">E: info@mccsingapore.com.sg</p>

          <gap :height="38" />
        </li>

        <li>
          <p class="f-f-arial f-s-8 f-c-15 font-bold">
            {{ $t("message.footer.staff") }}
          </p>
          <gap :height="10" />
          <a
            @click="openNew($store.state.mccPortalUrl)"
            style="text-decoration: none; cursor: pointer"
            class="f-f-arial f-s-11 f-c-16 label-hover operations"
          >
            <p>{{ $t("message.footer.employee") }}</p>
          </a>
        </li>

        <li>
          <p class="f-f-arial f-s-8 f-c-15 font-bold">
            {{ $t("message.footer.other") }}
          </p>
          <gap :height="10" />

          <!--

          <p class="f-f-arial f-s-11 f-c-16 label-hover">
            {{ $t("message.footer.career") }}
          </p>

          <gap :height="15" />

          <p class="f-f-arial f-s-11 f-c-16 label-hover">
            {{ $t("message.footer.privacy") }}
          </p>

          <gap :height="15" />

          <div class="flex-row">
            <p class="f-f-arial f-s-11 f-c-16 label-hover">
              {{ $t("message.footer.language") }} :
            </p>


                     <select
              v-model="$i18n.locale"
              @click="showMenuList = true"
              @change="changeLang($i18n.locale)"
              style="position: relative; margin-left: 1rem; top: -0.5rem"
            >
              <option
                v-for="(lang, i) in langs"
                :key="`Lang${i}`"
                :value="lang[1]"
              >
                {{ lang[0] }}
              </option>
            </select>


          </div>
          -->

          <div
            type="button"
            class="contact"
            style="cursor: pointer"
            @click="showContactUs"
          >
            <p class="f-f-arial f-s-11 f-c-16 label-hover">
              {{ $t("message.footer.contact") }}
            </p>
          </div>
          <gap :height="10"></gap>
          <div
            type="button"
            class="contact"
            style="cursor: pointer"
            @click="openNew('/hr')"
          >
            <p class="f-f-arial f-s-11 f-c-16 label-hover">
              {{ $t("message.footer.career") }}
            </p>
          </div>
        </li>
      </ul>
    </div>

    <gap :height="84" />

    <p class="flex-row justify-content-center f-c-14 f-f-arial f-s-8">
      China Jingye Engineering Corporation Limited (Singapore Branch) | China
      Jingye Construction Engineering (S) Pte Ltd | Mcc Land (Singapore) Pte Ltd
    </p>

    <gap :height="20" />
  </footer>
</template>

<script>
import imgSvg from "../../assets/imgs/Group 871.svg";

export default {
  name: "Footer",
  data() {
    return {
      imgSvg,
      langs: [
        ["English", "en"],
        ["中文", "zh"]
      ]
    };
  },

  methods: {
    showContactUs: function() {
      this.$store.commit("flip");
      console.log(this.$store.state.showContectUs);
    },

    changeLang: function(what) {
      this.$store.commit("changeLang", what);
      //console.log(what)
    },

    goto: function(where) {
      this.$router.push("/" + where);
    },
    openNew(where) {
      window.open(where);
    }
  }
};
</script>

<style lang="scss" scoped type="text/scss">
footer {
  background-color: #f7f8fa;
  overflow: hidden;
  ul {
    a {
      p {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    flex-direction: row;
    padding-top: 8.8rem;

    li:nth-child(2) {
      margin-left: 6.85rem;
    }

    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4) {
      padding: 0 6.85rem;
      border-right: 1px solid rgba(103, 104, 111, 0.2);
    }

    li:nth-child(4) {
      border-right: 0;
    }
  }

  .contact {
    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }
}
</style>
