<template>
  <header class="flex-row position-fix bg-c-0 z-index-100">
    <p class="flex-1 flex-row align-items-center left">
      <router-link to="/" style="outline: none">
        <img
          style="width: 3.244vw; height: 2.063vw"
          :src="imgSvg"
          class="cursor-pointer"
        />
      </router-link>
    </p>

    <div class="home-btn" @click="goto('')">
      {{ $t("message.header.home") }}
    </div>

    <div class="home-btn-line">

    </div>

    <div class="lang-switch">
      <p
        class="p-1"
        :style="{
          color:
            lang === false ? 'rgba(74, 145, 242, 1)' : 'rgba(149, 149, 149, 1)',
        }"
      >
        中文
      </p>
      <label class="switch">
        <input
          v-model="lang"
          @change="changeLangSimple()"
          type="checkbox"
          checked
        />
        <span class="slider round"></span>
      </label>
      <p
        :style="{
          color:
            lang === true ? 'rgba(74, 145, 242, 1)' : 'rgba(149, 149, 149, 1)',
        }"
        class="p-2"
      >
        EN
      </p>
    </div>

    <p
      v-if="!showMenuList"
      ref="boxMenu"
      class="box-menu cursor-pointer flex-row"
      @click="onClickMenu"
    >
      <span class="text-menu">{{ $t("message.menu") }}</span>
      <span class="icon-menu position-relative">
        <em class="line line-1"></em>
        <em class="line line-2"></em>
        <em class="line line-3"></em>
      </span>
    </p>

    <div
      class="menu-list"
      :style="{ width: showMenuList ? '413px' : 0 }"
      ref="sideMenu"
      tabindex="0"
    >
      <div
        v-if="showMenuList"
        class="row-icon flex-row"
        @blur="showMenuList = false"
      >
        <i
          @click="showMenuList = false"
          class="iconfont iconclose"
          style="cursor: pointer"
        ></i>
      </div>

      <div class="links-1 flex-column">
        <div class="item" @click="goto('')">
          {{ $t("message.header.home") }}
        </div>
        <div class="item" @click="goto('urban-design')">
          {{ $t("message.header.consultation") }}
        </div>
        <div class="item" @click="goto('property-dev')">
          {{ $t("message.header.real-estate") }}
        </div>
        <div class="item" @click="goto('construction')">
          {{ $t("message.header.construction") }}
        </div>

        <!--
           <div>
          {{ $t("message.header.language") }}
          <select
            v-model="$i18n.locale"
            @click="showMenuList = true"
            @change="changeLang($i18n.locale)"
            style="margin-left: 1rem"
          >
            <option
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang[1]"
            >
              {{ lang[0] }}
            </option>
          </select>
        </div>

        -->
      </div>

      <div class="line"></div>

      <div class="links-2 flex-column">
        <div class="item" @click="goto('all-news')">
          {{ $t("message.header.mcc-news") }}
        </div>

        <div class="item" @click="goto('about-us')">
          {{ $t("message.header.about-us") }}
        </div>
        <div class="item" @click="goto('history')">
          {{ $t("message.header.history") }}
        </div>
      </div>
    </div>

    <!--<el-header style="height: 94px; border: 1px solid #eee">
      <div class="container-fluid">
        <div class="row">
          <div class="col-2">
            <span style="position: relative; left: 80px"><img
                class="resize"
                src="../assets/img/Group 871.svg"
                style="padding: 23px"
            /></span>
          </div>
          <div class="col-8"></div>
          <div
            class="col-2"
            style="
              padding: 34px;
              color: var(&#45;&#45;unnamed-color-000000);
              text-align: left;
              font: normal normal normal 24px/35px Source Sans Pro;
              letter-spacing: 0.96px;
              color: #000000;
              opacity: 1;
            ">
            Menu

            <svg
              aria-hidden="true"
              width="30px"
              height="30px"
              style="margin-left: 20px"
            >
              <use xlink:href="#iconmenu_normal"></use>
            </svg>
          </div>
        </div>
      </div>
    </el-header>-->
  </header>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import imgSvg from "../../assets/imgs/Group 871.svg";

export default {
  name: "Header",
  data() {
    return {
      imgSvg,
      showMenuList: false,
      timer: null,
      lang: true,
      langs: [
        ["English", "en"],
        ["中文", "zh"],
      ],
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  methods: {
    changeLang: function (what) {
      this.showMenuList = false;
      this.$store.commit("changeLang", what);

      //console.log(what);
    },

    changeLangSimple: function () {
      if (this.lang === false) {
        this.$i18n.locale = "zh";
        this.$store.commit("changeLang", "zh");
      } else {
        this.$i18n.locale = "en";
        this.$store.commit("changeLang", "en");
      }
    },
    onClickMenu() {
      const el = this.$refs.boxMenu;
      this.$refs.sideMenu.focus();

      anime({
        targets: el,
        translateX: 0,
        easing: "easeInOutQuad",
        duration: 300,
      });

      this.timer = setTimeout(() => {
        this.showMenuList = true;
      }, 200);
    },

    goto: function (where) {
      this.$router.push("/" + where);
      this.showMenuList = false;
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
header {
  border-top: 4px solid #4a91f2;
  border-bottom: 2px solid #e3e3e3;
}
/*
@media screen and (max-width: 1024px) {
  header {
    @include box-size(100%, 50px);

    .left {
      padding-left: 10rem;
    }

    .box-menu {
      @include box-size-line-height(110px, 50px);

      span:first-child {
        letter-spacing: 0.96px;
        padding-left: 17px;
        padding-right: 10px;
        font-size: 15px;
      }

      .icon-menu {
        .line {
          background-color: #1381ce;
          transform-origin: 7px;
          position: absolute;
          height: 2px;
          left: 75%;
          transition: all 500ms ease;
        }
        .line-1 {
          width: 30px;
          margin-top: 15px;
        }
        .line-2 {
          width: 20px;
          margin-top: 25px;
        }
        .line-3 {
          width: 25px;
          margin-top: 35px;
        }
      }

      &:hover {
        .line-2,
        .line-3 {
          width: 30px;
        }
      }
    }

    .menu-list {
      width: 0;
      height: 100vw;
      background-color: rgba(210, 227, 250, 1);
      //padding-left: 14rem;
      //padding-right: 53px;
      box-shadow: 0px 3px 6px #00000029;
      transition: width 300ms ease-in-out;

      .row-icon {
        margin-left: 380.7px;
        margin-top: 15.79px;
      }
      img {
        width: 13.43px;
        height: 13.42px;
      }
      .links {
        padding: 56.79px 0 0 58px;

        div {
          &:hover {
            text-decoration: underline;
          }

          width: fit-content;
          cursor: pointer;
          margin-bottom: 28px;
          font-size: 18px;
        }
      }
    }
  }
}

*/

@media screen and (min-width: 0px) {
  header {
    @include box-size(100%, 4.593rem);

    .left {
      padding-left: 10rem;
    }

    .home-btn{
      &:hover{
        color: #0055A5;
        background-color: #D2E3FA;
      }
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      right: 25rem;
      height: 100%;
      line-height: 4.5rem;
      font-size: 1.5rem;
      width: 11.6rem;
      font-family: Arial;
    }
    
    .home-btn-line{
      height: 2.3rem;
      border-left: solid #C6C6C6 1px;
      position: absolute;
      right: 25rem;
      margin-top: 1.2rem;
    }

    .lang-switch {
      display: flex;
      flex-direction: row;
      height: 1.2rem;
      margin-top: 1.5rem;

      p {
        position: absolute;
        font-size: 1.1rem;
        font-family: Arial;

        line-height: 1.7rem;
      }
      .p-1 {
        right: 20rem;
      }
      .p-2 {
        right: 13.4rem;
      }
      .switch {
        position: absolute;
        display: inline-block;
        right: 16rem;
        width: 3rem;
        height: 1.7rem;
      }

      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #2196f3;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 1.3rem;
        width: 1.3rem;
        left: 0.2rem;
        bottom: 0.2rem;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #2196f3;
      }

      input:focus + .slider {
        box-shadow: 0 0 0.05rem #2196f3;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(1.3rem);
        -ms-transform: translateX(1.3rem);
        transform: translateX(1.3rem);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 1.7rem;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .box-menu {
      //@include box-size-line-height(20.3rem, 9.4rem);
      position: absolute;
      right: 0;
      @include box-size-line-height(13.4rem, 4.5rem);
      span:first-child {
        letter-spacing: 0.96px;
        padding-left: 31 * 100vw/1366;
        font-size: 1.098vw;
        letter-spacing: 0.06rem;
        font-family: Source Sans Pro;
      }
      span:nth-child(2) {
        letter-spacing: 0.96px;
        margin-left: 1.129rem;
        padding-right: 2.559rem;
        //font-size: 2.4rem;
      }

      .icon-menu {
        .line {
          background-color: #1381ce;
          transform-origin: 7px;
          position: absolute;
          height: 2px;
          // left: 100%;
          transition: all 500ms ease;
        }
        .line-1 {
          width: 2.613rem;
          margin-top: 1.435rem;
        }
        .line-2 {
          width: 1.565rem;
          margin-top: 1.435rem+0.996rem;
        }
        .line-3 {
          width: 1.992rem;
          margin-top: 1.435rem+0.996+0.996rem;
        }
      }

      &:hover {
        .line-2,
        .line-3 {
          width: 2.613rem;
        }
      }
    }

    .menu-list {
      z-index: 100;
      width: 0;
      height: 100vw;
      background-color: rgba(210, 227, 250, 1);
      //padding-left: 14rem;
      //padding-right: 53px;
      box-shadow: 0px 3px 6px #00000029;
      transition: width 300ms ease-in-out;

      .row-icon {
        margin-left: 380.7px;
        margin-top: 15.79px;
      }
      img {
        width: 13.43px;
        height: 13.42px;
      }

      .links-1,
      .links-2 {
        padding: 56.79px 0 0 58px;
        div {
          &:hover {
            text-decoration: underline;
          }
          width: fit-content;
          cursor: pointer;
          margin-bottom: 28px;
          font-size: 18px;
        }
      }

      .line {
        width: 85%;
        height: 3px;
        border-bottom: solid #aeb0b3 1px;
        margin-left: 29px;
      }

      .links-2 {
        padding-top: 38px;
      }
    }
  }
}
</style>
